<template>
  <div>
    <el-dialog :close-on-click-modal="false" destroy-on-close title="打印设置" v-model="dialogFormVisibleManager"  class="button_bg" width="670px">
      <el-scrollbar :maxHeight="contentStyleObj">
        <el-form ref="dataForm" label-position="left" label-width="160px" size="small">
          <el-form-item label="账簿选择：" v-if="this.name == 'book_all_account' || this.name == 'book_detail_account'">
            <el-checkbox-group v-model="temp.bookNames">
              <el-checkbox label="book_detail_account">明细账</el-checkbox>
              <el-checkbox label="book_all_account">总账</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="所属期:" v-if="datePeriod">
            <el-date-picker
              v-model="startPeriod"
              type="month"
              placeholder="选择月"
              value-format="YYYYMM">
            </el-date-picker> --
            <el-date-picker
              v-model="endPeriod"
              type="month"
              placeholder="选择月"
            
              value-format="YYYYMM">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="是否打印封面:" v-if="this.name == 'book_all_account' || this.name == 'book_detail_account'">
            <el-switch v-model="fm" :active-value="1" :inactive-value="0" /> 
            <span style="margin-left: 10px;" v-if="(this.name == 'book_all_account' || this.name == 'book_detail_account') && fm == 1"><el-checkbox v-model="showSubject" label="科目名称"></el-checkbox><el-checkbox v-model="showFzr" label="负责人姓名"></el-checkbox></span>
          </el-form-item>
          <el-form-item label="分册方式:" v-if="(this.name == 'book_all_account' || this.name == 'book_detail_account') && fm">
            <el-select v-model="volumeType" placeholder="请选择分册方式" style="margin:0;width: 100px">
              <el-option label="不分册" :value="0"></el-option>
              <el-option label="按月" :value="1"></el-option>
              <el-option label="按季" :value="2"></el-option>
              <el-option label="按年" :value="3"></el-option>
              <el-option label="按张" :value="4"></el-option>
            </el-select>
            <span style="margin-left: 10px;" v-if="fm && volumeType == 4">
             <el-input-number style="width: 90px;margin:0 3px" v-model.number="splitPageNum" controls-position="right"/>张
            </span>
          </el-form-item>
          <el-form-item  label="是否只显示有金额科目 ：" v-if="this.name == 'book_subject_balance' || this.name == 'book_fzhs_subject'">
            <el-switch v-model="not0" /> 
          </el-form-item>
          <el-form-item label="金额是否带千分符：">
            <el-switch v-model="thousandSep" /> 
          </el-form-item>
          <el-form-item label="金额是否右对齐:"  v-if="[
              'book_subject_balance',
              'book_all_account',
              'book_detail_account',
            ].includes(name)
          ">
            <el-switch v-model="contentRight" />
          </el-form-item>
          <el-form-item label="选择级别 ：" v-if="this.name == 'book_subject_balance' || this.name == 'book_fzhs_subject'">
            <el-input placeholder="1" style="width: 90px;" :disabled="true" />
            <span>-</span>
            <el-input placeholder="请输入级别" v-model.number="temp.level" style="width: 90px" />
          </el-form-item>
          <el-form-item label="科目:" v-if="status.subje && !this.from">
            <subject-list-all v-model:subjectId="listQuery1.subjectId" clear></subject-list-all>
            <span style="color:red;font-size: 20px;vertical-align: middle;">*</span><span style="color:red">不选默认打印全部</span>
          </el-form-item>
          <el-form-item label="辅助核算项目:" v-if="status.subjeFz">
            <el-select v-model="listQuery1.fzhsItemId" placeholder="请选择" style="margin:0">
              <el-option label="全部" :value="0"></el-option>
              <el-option
                v-for="item in fzList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="纸张类型:" v-if="status.zzlx">
            <el-radio-group v-model="temp.paperType">
              <!-- <subject-list subjectName="测试科目" v-model:subjectId="listQuery.id" v-model:fzhsItemId="listQuery.id2" :codes="['1001','1002']"></subject-list> -->
              <el-radio label="A4-S" ref="A4-S" v-if="status.zzlx4S" border>A4纸竖向</el-radio>
              <el-radio label="A4-H" ref="A4-H" border v-if="status.zzlx4H">A4纸横向</el-radio>
              <el-radio label="A5-H" ref="A5H" border v-if="status.zzlx5H">凭证纸</el-radio>
              <el-radio label="" border v-if="status.zzlxk">默认纸张</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="科目选择：" v-if="status.kmxz">
            <el-radio-group v-model="temp.level">
              <el-radio :label="0">默认</el-radio>
              <el-radio :label="1">一级</el-radio>
              <el-radio :label="2">二级</el-radio>
              <el-radio :label="3">三级</el-radio>
              <el-radio :label="4">四级</el-radio>
              <el-radio :label="5">五级</el-radio>
              <el-radio :label="100">最下级科目</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="规则：" v-if="status.gz">
            <el-radio-group v-model="temp.merge">
              <el-radio :label="0">默认</el-radio>
              <el-radio :label="1">合并</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item  v-if="status.not0"> 
            <el-checkbox v-model="temp.not0" label="余额为0不显示"></el-checkbox>
          </el-form-item>
          <el-form-item v-if="status.jeNot"> 
            <el-checkbox v-model="temp.jeNot0" label="金额为0不显示"></el-checkbox>
          </el-form-item>
          <el-form-item v-if="status.fseNot"> 
            <el-checkbox v-model="temp.fseNot0" label="发生额为0不显示"></el-checkbox>
          </el-form-item>
          <el-form-item v-if="status.fse0Ye1"> 
            <el-checkbox v-model="temp.fse0Ye1" label="无发生额且余额为0不显示"></el-checkbox>
          </el-form-item>
          <el-form-item label="快捷选择:" v-if="status.kjxz">
            <el-radio-group @change="kjChange" v-model="kj">
              <el-radio :label="1" border>凭证纸(竖)</el-radio>
              <el-radio :label="2" border>针式打印机</el-radio>
              <el-radio :label="3" border>凭证纸(横)</el-radio>
              <el-radio :label="4" border>默认</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选项:" v-if="status.xx">
            <el-radio-group v-model="temp.wb">
              <el-radio :label="0">正常</el-radio>
              <el-radio :label="1">外币</el-radio>
              <el-radio :label="2">数量金额</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="字体：" v-if="status.zt">
            <el-radio-group v-model="temp.ttf">
              <el-radio :label="1">宋体</el-radio>
              <el-radio :label="2">微软雅黑</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="显示条数：" v-if="status.xsts">
            <el-input v-model.number="temp.num" placeholder="请输入显示条数" class="width_80"></el-input>
          </el-form-item>
          <el-form-item label="字体大小" v-if="status.ztdx">
            <el-input type="number" v-model.number="temp.ttfSize" placeholder="请输入字体大小" class="width_80"></el-input>
          </el-form-item>
          <el-form-item label="左偏移(mm)：" v-if="status.zpy">
            <el-input type="number" v-model.number="temp.left" placeholder="请输入左偏移" class="width_80"></el-input>
          </el-form-item>
          <el-form-item label="右偏移(mm)：" v-if="status.ypy">
            <el-input type="number" v-model.number="temp.right" placeholder="请输入右偏移" class="width_80"></el-input>
          </el-form-item>
          <el-form-item label="纵偏移(mm)：" v-if="status.mxzZpy">
            <el-input type="number" v-model.number="temp.top" placeholder="请输入纵偏移" class="width_80"></el-input>
          </el-form-item>
          <div v-if="status.tbwh">
            <el-form-item label="上偏移(mm)：">
            <el-input type="number" v-model.number="temp.top" placeholder="请输入上偏移" class="width_80"></el-input>
            </el-form-item>
            <el-form-item label="下偏移(mm)：">
            <el-input type="number" v-model.number="temp.bottom" placeholder="请输入下偏移" class="width_80"></el-input>
            </el-form-item>
            <el-form-item label="宽度(mm)：">
              <el-input type="number" v-model.number="temp.wide" placeholder="请输入宽度" class="width_80"></el-input>
            </el-form-item>
            <el-form-item label="高度(mm)：">
            <el-input type="number" v-model.number="temp.long" placeholder="请输入高度" class="width_80"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="选项：" v-if="status.xuanxiang">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="屏蔽参考信息"></el-checkbox>
              <el-checkbox label="屏蔽业务日期2"></el-checkbox>
              <el-checkbox label="屏蔽序号"></el-checkbox>
              <el-checkbox label="屏蔽制作时间"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisibleManager = false" size="small">取消</el-button>
          <el-button type="primary" @click="print()" size="small" :loading="btnLoading">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { printSetOne,accountBook } from "@/api/printSet";
import { inventoryDetail ,InOutInventoryDetail,printFixedAssets } from '@/api/printSet'

export default {
  props: {
    name: {
      type: String,
    },
    listQuery1: {
      type: Object,
    },
    DY: {
      type:Number,
      default:0
    },
    optionType: {
      type:String,
      default: '1'
    },
    datePeriod:{
      type:Boolean,
      default:false
    },
    fzList:{
      type:Array,
      default:[]
    },
    from:{
      type: String,
      default:""
    },
    //判断是否显示余额为0不显示、金额为0不显示、发生额为0不显示
    showChoose: {
      type:Boolean,
      default:false
    },
  },
  data () {
    return {
      checkList: [],
      kj:"",
      temp: {
        level:0,
        merge:0,
        type: "pdf",
        comId: 0,
        paperType: "A4-S",
        long: 0,//pdf尺寸
        wide: 0,//pdf尺寸
        num: 0,//生成一页条数
        left: 0,//pdf左偏移
        ttfSize: 8,
        top: 0,//偏移
        right: 0,//偏移
        bottom:0,//偏移
        ttf:1,
        wb:0,
        bookNames:[]
      },
      status:{
        subje:false,
        kmxz:false,
        gz:false,
        type:false,
        zzlx: false,//xxx
        kjxz: false,
        xx: false,
        zt: false,
        xsts: false,
        ztdx: false,
        zpy: false,
        ypy: false,
        xuanxiang: false,
        tbwh: false,
        sublall:false,
        subjeFz:false,
        not0:false,
        mxzZpy:false,
        jeNot:false,
        fseNot:false,
        fse0Ye1:false
      },
      dialogFormVisibleManager: false,
      startPeriod:'',
      endPeriod:'',
      not0:false,
      fm:0,
      comIds:[],
      period:'',
      ids:[],
      optionType:'',
      btnLoading:false,
      contentStyleObj:{},
      showSubject:false,
      showFzr:false,
      volumeType:0,
      splitPageNum:1,
      thousandSep:true,
      contentRight:true
    };
  },
  mounted(){
    this.contentStyleObj = this.$getHeight(300)
  },
  methods: {
    init(comIds,period,ids,wbStatatus){
      if(comIds){
        this.comIds = comIds
      }
      if(period){
        this.period = period
      }
      if(ids){
        this.ids = ids
      }
      if(wbStatatus == 'wb'){
        this.optionType = '1'
      }else if(wbStatatus == 'sl'){
        this.optionType = '2'
      }else{
        this.optionType = ''
      }
      this.initTemp()
      this.dialogFormVisibleManager = true
      printSetOne({type:'pdf', bookName:this.name}).then(res=>{
        this.temp = res.data.data.info?res.data.data.info:this.temp
        this.temp.type = "pdf"
        this.temp.bookNames = [this.name]
        if (!this.temp.paperType) {
          this.temp.paperType = "A4-S";
        }
        if(!this.temp.ttfSize){
          this.temp.ttfSize = 8
        }
        if(this.name == 'book_detail_account' || this.name == 'book_all_account'){
          this.temp.ttfSize = 8
          this.temp.not0 = this.listQuery1.not0
          this.temp.fseNot0 = this.listQuery1.fseNot0
          this.temp.jeNot0 = this.listQuery1.jeNot0
          this.temp.fse0Ye1 = this.listQuery1.fse0Ye1
        }
      })
    },
    initTemp(){
      if(this.name == "book_voucher"){
        this.status.zzlx = true
        this.status.zzlx4S =true
        this.status.kjxz = true
        this.status.xx = true
        this.status.zt = true
        this.status.xsts = true
        this.status.ztdx = true
        this.status.zpy = true
        this.status.ypy = true
        this.status.xuanxiang = true
      }else if(this.name == "book_subject_balance") {
        this.status.zzlx4H = true
        this.status.zzlx = true
        this.status.zt = true
        this.status.zpy = true
        this.status.ypy = true
        this.status.zzlx5H = true
        this.status.zzlx4S =true
        this.status.ztdx = true
      }else if(this.name == "book_fzhs_subject") {
        this.status.zzlx4H = true
        this.status.zzlx = true
        this.status.zt = true
        this.status.zpy = true
        this.status.ypy = true
        this.status.zzlx5H = true
        this.status.ztdx = true
      }else if(this.name == "book_detail_account") {
          this.status.kmxz = true
          this.status.gz = true
          this.status.zzlx = true
          this.status.sublall = true
          this.status.zzlx4H = true
          this.status.zzlx4S = true
          this.status.subje = true
          this.status.zpy = true
          this.status.ypy = true
          this.status.mxzZpy = true
          this.status.not0 = true
          this.status.jeNot = true
          this.status.fseNot = true
          this.status.fse0Ye1 = true
          this.status.ztdx = true
      }else if(this.name == "book_fzhs_detail_account") {
          this.status.kmxz = false
          this.status.gz = true
          this.status.zzlx = true
          this.status.sublall = true
          this.status.zzlx4H = true
          this.status.zzlx4S = true
          this.status.subjeFz = true
          this.status.ztdx = true
          // this.status.not0 = true
      }
      else if(this.name == "book_all_voucher") {
          this.status.zzlx = true
          this.status.zt = true
          this.status.zpy = true
          this.status.ypy = true
          this.status.zzlx5H = true
          this.status.zzlx4H = true
          this.status.zzlx4S = true
          this.status.ztdx = true
      }else if(this.name == "book_all_account") {
          this.status.kmxz = true
          this.status.zzlx = true
          this.status.zzlx4H = true
          this.status.zzlx4S = true
          this.status.gz = true
          this.status.zpy = true
          this.status.ypy = true
          this.status.mxzZpy = true
          this.status.not0 = true
          this.status.jeNot = true
          this.status.fseNot = true
          this.status.ztdx = true
      }else if(this.name == "book_diary_account") {
          this.status.zzlx = true
          this.status.zzlx4H = true
          this.status.zzlx4S = true
          this.status.subje = true
          this.status.ztdx = true
      }else if(this.name == "inventoryPeriod") {
          this.status.zzlx = true
          this.status.zzlx5H = true
          this.status.zzlx4H = true
          this.status.zt = true
          this.status.zpy = true
          this.status.ypy = true
          this.status.zzlxk = true
      }else if(this.name == "inventoryDetail") {
          this.status.zzlx = true
          this.status.zzlx4H = true
          this.status.zt = true
          this.status.zpy = true
          this.status.ypy = true
      }else if(this.name == "assets") {
          this.status.zzlx = true
          this.status.zzlx4H = true
          this.status.zt = true
          this.status.zpy = true
          this.status.ypy = true
          this.status.zzlx5H = true
      }
    },
    // 打印
    print(){
      if(this.temp.left > 50){
        this.$qzfMessage('左偏移最大为50！',1);
        return
      }
      if(this.temp.right > 25){
        this.$qzfMessage('右偏移最大为25！',1);
        return
      }
      if((this.name == 'book_detail_account' || this.name == 'book_all_account' || this.name == 'book_subject_balance' || this.name == 'book_fzhs_detail_account' || this.name == 'book_fzhs_subject' || this.name == 'book_all_voucher' || this.name == 'book_diary_account') && (this.temp.ttfSize < 8 || this.temp.ttfSize > 12)){
        this.$qzfMessage('字体大小区间为8到12！',1);
        return
      }
      let params = Object.assign({}, this.temp)
      params.query =  Object.assign({}, this.listQuery1)
      // params.query.name = ''
      if(this.datePeriod){
        params.query.beginTime = this.startPeriod
        params.query.endTime = this.endPeriod
        params.query.jeNot0 = this.not0
      }
      if(this.comIds.length != 0){
        params.comIds = this.comIds
      }else{
        params.comIds = [this.listQuery1.comId]
      }
      if(this.period){
        params.period = this.period
      }
      if(this.ids){
        params.ids = this.ids
      }
      params.not0 = this.not0
      params.thousandSep = this.thousandSep
      params.contentRight = this.contentRight
      if(this.name == 'book_all_account' || this.name == 'book_detail_account'){
        params.fm = this.fm
        params.showSubject = this.showSubject
        params.showFzr = this.showFzr,
        params.volumeType = this.volumeType
        params.splitPageNum = this.splitPageNum*1
        params.query.level = this.temp.level
        params.query.not0 = this.temp.not0
        params.query.fseNot0 = this.temp.fseNot0
        params.query.jeNot0 = this.temp.jeNot0
        params.query.fse0Ye1 = this.temp.fse0Ye1
      }
      if(params.bookNames.length == 0){
        this.$message({
          showClose: true,
          message: '账簿选择至少一条',
          type: 'warning'
        });
        return
      }
      if(this.listQuery1.endTime < this.listQuery1.beginTime) {
        this.$message({
          showClose: true,
          message: '请查看日期起止是否填写正确',
          type: 'warning'
        });
        return
      }
      if(params.comIds.length > 1 || params.bookNames.length == 2){
        params.zip = 1
      }else{
        params.zip = 0
      }
      this.btnLoading = true
      if(this.name  == "inventoryPeriod"){
        if(!params.paperType){
          params.paperType = 'A4-H'
        }
        // params.paperType = 'A4-H'
        inventoryDetail(params).then(res => {
          this.btnLoading = false
          window.open(res.data.data.url); 
          this.dialogFormVisibleManager = false
        })
        return
      }
      // 出入库明细
      if(this.name == "inventoryDetail"){
        if(!params.paperType){
          params.paperType = 'A4-H'
        }
        InOutInventoryDetail(params).then(res => {
          this.btnLoading = false
          window.open(res.data.data.url); 
          this.dialogFormVisibleManager = false
        })
        return
      }
      if(this.name  == "assets"){
        if(params.paperType != 'A4-H' && params.paperType != 'A5-H'){
          params.paperType = 'A4-H'
        }
        printFixedAssets(params).then(res => {
          this.btnLoading = false
          window.open(res.data.data.url); 
          this.dialogFormVisibleManager = false
        })
        return
      }
      if(this.name == 'book_subject_balance' || this.name == 'book_fzhs_subject'){
        params.optionType = this.optionType
      }
      accountBook(params).then(res=>{
        this.btnLoading = false
        this.dialogFormVisibleManager = false
        if(res.data.msg == 'success'){
          if (res.data.data.msg) {
           this.$qzfMessage(res.data.data.msg);
          } else {
            window.open(res.data.data.path);
          }
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.width_80{
  width: 80%;
}
</style>


